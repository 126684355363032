@import 'normalize';

// responsivity
$media-base: 300px;
$media-bp-1: 600px;
$media-bp-2: 700px;
$media-bp-3: 930px;
$hero-bp-mid: 1700px;
$max-content-width: 1024px;

// colors
$text-on-light: #333;
$text-faint-on-light: #999;
$text-on-dark: #eee;
$text-faint-on-dark: #999;
$bg-darkest: #000;
$bg-dark: #555;
$bg-lightish: #ccc;
$bg-light: #eee;
$bg-lightest: #fff;
$bg-success: #e4f5e4;
$bg-error: #f7d4d4;
$border-light: #ccc;
$border-dark: #222;

// font sizes
$f-scale: 1.25;
$f-base: 17px;
$f-base-bp1: 18px;
$f-base-bp2: 20px;
$f-base-bp3: 22px;
$f-normal: 1em;
$f-small: $f-normal / $f-scale;
$f-medium: $f-normal * $f-scale;
$f-big: $f-medium * $f-scale;
$f-gigantic: $f-big * $f-scale;

$f-family-main: 'Raleway', Helvetica Neue, Helvetica, sans-serif;
$f-family-header: 'Source Sans Pro', Helvetica Neue, Helvetica, sans-serif;

$h-std-margin: $f-medium;

// mixins
@mixin flexrow {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@mixin flexcol {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@mixin img-radius {
  border-radius: 20px 0px;
}

@mixin hero ($num) {
  background: url(/img/hero-#{$num}-small.jpg) no-repeat center center;
  background-size: cover;

  @media (min-width: $media-bp-2) {
    background-image: url(/img/hero-#{$num}-mid.jpg);
  }

  @media (min-width: $hero-bp-mid) {
    background-image: url(/img/hero-#{$num}-full.jpg);
  }
}

@-ms-viewport {
  width: device-width;
}

html {
  font-size: $f-base;

  @media (min-width: $media-bp-1) {
    font-size: $f-base-bp1;
  }

  @media (min-width: $media-bp-2) {
    font-size: $f-base-bp2;
  }

  @media (min-width: $media-bp-3) {
    font-size: $f-base-bp3;
  }
}

body {
  background: $bg-lightest;
  color: $text-on-light;
  font-family: $f-family-main;
  font-size: $f-normal;
  width: 100%;
  min-width: $media-base;
  min-height: 550px;
}

h1, h2, h3 {
  font-family: $f-family-header;
  color: $text-on-light;
  font-weight: 200;
}

h1 {
  font-size: $f-gigantic;
}

h2 {
  font-size: $f-big;
}

h3 {
  font-size: $f-medium;
}

a, a:visited, a:link {
  color: inherit;
  font-weight: 600;

  &:hover {
    text-decoration: none;
  }
}

.headerWrapper {
  width: 100%;
  border-bottom: 1px solid $border-dark;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 100;
  background-color: $bg-lightest;
}

.header {
  width: 100%;
  max-width: $max-content-width;
  margin: 0px auto;
  position: relative;

  @media (min-width: $media-bp-3) {
    @include flexrow;
    justify-content: space-between;
  }

  .logoCont {
    @include flexrow;
    justify-content: space-between;
    align-items: center;
    padding: $f-small;

    .logo {
      height: $f-big;
    }

    .toggleMenu {
      @media (min-width: $media-bp-3) {
        display: none;
      }
      img {
        height: $f-big;
      }
    }
  }


  .headerCont {
    display: none;
    position: absolute;
    top: $f-big + $f-small * 2 + 0.05em;
    right: 0px;
    width: 100%;
    background-color: $bg-lightest;
    margin: 0px;
    padding: 0px;
    z-index: 5;
    border-bottom: 1px solid $border-dark;

    @media (min-width: $media-bp-3) {
      @include flexrow;
      justify-content: flex-end;
      position: relative;
      top: 0px;
      width: auto;
      border-bottom: none;
    }

    li {
      display: block;
      font-size: $f-normal;
      margin: 0px;
      padding: 0px;
      border-top: 1px solid $border-light;

      @media (min-width: $media-bp-3) {
        border-top: none;
        font-size: $f-normal;
      }
    }

    a {
      display: block;
      padding: $h-std-margin / 2;
      font-weight: 200;
      text-decoration: none;
      font-family: $f-family-header;

      @media (min-width: $media-bp-3) {
        padding: $h-std-margin $h-std-margin / 2;
      }

      &.twitterLink {
        position: relative;
        top: 2px;
      }

      &:hover {
        background-color: $bg-darkest;
        color: $text-on-dark;

        &.twitterLink {
          background-color: inherit;
        }
      }
    }
  }
}

$hero-base-min-height: 300px;

.hero, .heroMainBlurbCont {
  height: 40%;
  min-height: $hero-base-min-height;
  width: 100%;

  @media (min-width: $media-bp-1) {
    min-height: $hero-base-min-height * 1.15;
  }

  @media (min-width: $media-bp-3) {
    min-height: $hero-base-min-height * 1.6;;
  }

  &.mainHero {
    height: 50%;
  }

  &.mainHero .heroImg:after {
    content: '\A';
    position: absolute;
    width: 100%;
    height:100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.0);
  }

}

.hero {
  @include flexrow;
  align-items: center;
  position: relative;
  border-bottom: 1px solid $border-dark;
  border-top: 1px solid $border-dark;

  .heroImg {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    z-index: 1;
  }

  .hero1 {
    @include hero(1);
  }

  .hero2 {
    @include hero(2);
  }

  .hero3 {
    @include hero(3);
  }

  .hero4 {
    @include hero(4);
  }

  .hero5 {
    @include hero(5);
  }

  .hero6 {
    @include hero(6);
  }

  .heroContent {
    z-index: 2;
    text-align: center;
    color: $text-on-dark;
    max-width: $max-content-width;
    margin: 0px auto;
    position: relative;
    height: 100%;
    width: 100%;
  }

  .heroBlurb {
    text-shadow: 2px 2px 0px $bg-dark;
    margin: 0px auto;
    padding: 20px;
    position: relative;

    &.sectionBlurb {
      font-size: $f-gigantic;
      position: absolute;
      bottom: 0px;
      left: 0px;

      @media (min-width: ($max-content-width + 40px)) {
        padding-left: 0px;
      }
    }
  }

}

.heroMainBlurbCont {
  color: $text-on-dark;
  text-align: center;
  position: absolute;
  top: 0px;
  z-index: 3;

  .heroMainBlurb {
    position: relative;
    top: 23%;
  }

  .heroBlurb {

    .mainBlurb {
      font-size: $f-big * 0.8;

      @media (min-width: $media-bp-1) {
        font-size: $f-big;
      }
    }

    .subBlurb {
      font-size: $f-gigantic * 0.8;
      font-weight: 600;

      @media (min-width: $media-bp-1) {
        font-size: $f-gigantic;
      }
    }

  }

  .heroSymbol {
    height: 50%;
  }
}

.blogHero .heroMainBlurb {
  top: 28%;
}


.contentWrapper {
  background: $bg-lightest;
  padding: $h-std-margin;
  padding-top: 0.1em;
  flex: 1;
  max-width: $max-content-width;
  margin: 0px auto;
}


.intro {
  .cgPlusAppium {
    $h-cg-plus-appium: $f-gigantic * $f-scale;
    @include flexrow;
    height: $h-cg-plus-appium;
    margin-bottom: $h-std-margin;
    font-weight: 200;

    @media (min-width: $media-bp-2) {
      height: $h-cg-plus-appium * $f-scale;
    }

    img {
      height: 100%;

      &.smallPhone {
        height: 70%;
        position: relative;
        top: 15%;
      }
    }

    .plus, .equals {
      font-size: $h-cg-plus-appium * 1.2;
      margin: 0px $h-std-margin / 4;
      position: relative;
      top: -10px;

      @media (min-width: $media-bp-2) {
        top: 0px;
      }
    }

    .equals {
      top: -15px;

      @media (min-width: $media-bp-2) {
        top: -5px;
      }
    }
  }

  h1 {
    text-align: center;
  }

  .introBlurb {
    em {
      font-weight: 600;
      font-style: normal;
    }
  }
}

.testimonial, .bio {
  @media (min-width: $media-bp-2) {
    @include flexrow;
    align-items: center;
    margin-top: $h-std-margin;
  }
}

.testimonialPerson, .bioPerson {
  @include flexrow;
  justify-content: start;
  align-items: center;
  margin-bottom: $h-std-margin / 2;
  margin-top: $h-std-margin;

  @media (min-width: $media-bp-2) {
    @include flexcol;
    width: 8em;
    text-align: center;
    margin-top: 0px;
  }

  img {
    width: 5em;
    margin-right: $h-std-margin / 2;
  }
}

.testimonials {

  .testimonialQuote {
    @include flexrow;
    flex: 1;
    justify-content: start;
    text-align: justify;

    img {
      position: relative;
      top: -7px;
      height: 30px;
      width: 30px;
      margin-right: $h-std-margin / 3;

      &.quoteDown {
        margin-right: 0px;
        margin-left: $h-std-margin / 3;
      }
    }
  }
}

.bioDesc {

  @media (min-width: $media-bp-2) {
    flex: 1;
    margin-left: $h-std-margin;
  }
}

.services {
  ul {
    @include flexrow;
    flex-wrap: wrap;
    padding: 0px;

    li {
      @include flexcol;
      align-items: center;
      justify-content: start;
      margin: 0.5em;
      padding: 0.5em;
      max-width: 360px;
      background-color: $bg-light;

      @media (min-width: $media-bp-2) {
        width: 42%;
      }
    }

    em {
      font-style: normal;
      font-weight: 600;
      margin-bottom: $h-std-margin / 2;
      text-align: center;
    }

    i {
      display: block;
      color: $text-faint-on-light;
      font-size: $f-gigantic * 2;
    }
  }
}

.projects {
  .projectDetail {
    text-align: center;

    @media (min-width: $media-bp-2) {
      @include flexrow;
      align-items: center;
    }

    img {
      margin: 0px auto;
      margin-bottom: $h-std-margin;
      height: 3em;

      @media (min-width: $media-bp-2) {
        height: auto;
        width: 5em;
        margin-bottom: auto;
        margin-right: $h-std-margin;
      }
    }

    .projectDesc {
      text-align: left;
    }
  }
}

.about {
  li {
    margin-bottom: $h-std-margin / 2;
  }
}

.contact {
  form {

    max-width: 600px;

    margin: $h-std-margin auto;

    label, input, textarea {
      display: block;
      width: 100%;
      margin: 10px 0px;
    }

    input, textarea {
      border: 1px solid $bg-lightish;
      border-radius: 4px;
      padding: $h-std-margin / 4;
    }

    textarea {
      height: 200px;
    }

    .button {
      width: auto;
      margin-top: $h-std-margin;
      background: $bg-light;
      padding: $h-std-margin / 2;
    }

    label {
      margin-top: $h-std-margin;
      font-size: $f-small;
    }
  }

  .contactResponse {
    font-size: $f-small;
    width: 96%;
    margin: 0px auto;
    padding: 2%;
    border: 1px solid $text-faint-on-light;
    background: $bg-success;
    border-radius: 4px;
    margin-top: $h-std-margin;

    &.error {
      background: $bg-error;
    }
  }
}

.footer {
  width: 100%;
  background-color: $bg-dark;
  color: $text-faint-on-dark;
  border-top: 1px solid $border-dark;

  .copyright {
    font-size: $f-small;
    padding: 0px $h-std-margin;
    padding-top: $h-std-margin;
    text-align: center;

    &:last-child {
      padding-bottom: $h-std-margin;
    }
  }
}

.blog {
  .byline {
    font-size: $f-small;
    color: $text-faint-on-light;
    margin-top: -1 * $h-std-margin;
    margin-bottom: $h-std-margin;
  }
}
